import React, { useState } from "react";
import CommonBanner from "../../components/GlobalComponents/CommonBanner";
import FooterComponent from "../../components/FooterComponent";
import axios from "axios";
import Swal from "sweetalert2";

const ContactUsPage = () => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [mail, setMail] = useState("");
  const [mess, setMess] = useState("");
  const [serviceType, setServiceType] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = () => {
    setIsLoading(true);
    if (
      name.trim() === "" ||
      mail.trim() === "" ||
      phone.length < 10 ||
      mess.trim() === ""
    ) {
      Swal.fire("Error", `${phone && phone.length < 10 ? "Mobile number must be at least 10 characters long" : "Please fill in all fields"}`, "error");
      setIsLoading(false);
      return;
    }

    const emailBody = `<html>
          <body>
            <h3>Contact Request Details:</h3>
            <p><strong>Name: </strong>${name}</p>
            <p><strong>Contact No.: </strong>${phone}</p>
            <p><strong>Email: </strong>${mail}</p>
            <p><strong>Message: </strong>${mess}</p>
          </body>
        </html>`;

    const sentFrom = mail; // Replace with your email address
    const sentName = name;
    const subject = "Contact request information from Bhaijaan Dhaba website";
    const dataString =
      "emailBody=" +
      encodeURIComponent(emailBody) +
      "&sentFrom=" +
      encodeURIComponent(sentFrom) +
      "&sentName=" +
      encodeURIComponent(sentName) +
      "&subject=" +
      encodeURIComponent(subject) +
      "&sendmail=";

    axios
      .post("https://bhaijaandhaba.in/php_mailer/phpmailsend.php", dataString, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((response) => {
        console.log("email response", response.data);
        setIsLoading(false);
        if (response.data === "success") {
          Swal.fire("Success", "Email sent", "success");
          setName("");
          setMail("");
          setPhone("");
          setMess("");
        } else {
          Swal.fire("Error", "Failed to send email", "error");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setIsLoading(false);
        Swal.fire(
          "Error",
          "An error occurred while sending the email",
          "error"
        );
      });

    console.log("correct");
    // return true;
  };

  return (
    <>
      <CommonBanner />
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h5 className="section-title ff-secondary text-center text-warning fw-normal">
              Contact Us
            </h5>
            <h1 className="mb-5">Contact For Any Query</h1>
            <p className="mb-4">
              Follow us on Insert Social Media Links for exclusive updates,
              mouthwatering visuals, and behind-the-scenes peeks into the world
              of Bhaijaan Dhaba.
            </p>
          </div>
          <div className="row g-4">
            <div className="col-12">
              <div className="row gy-4">
                <div className="col-md-4">
                  <h5 className="section-title ff-secondary fw-normal text-start text-warning">
                    For Booking Mail At
                  </h5>
                  <p className="kkrutba-gmail-text">
                    <a href="mailto:bhaijaan.dhaba1@gmail.com" target="_blank">
                      <i className="fa fa-envelope-open text-warning me-2"></i>
                      bhaijaan.dhaba1@gmail.com
                    </a>
                  </p>
                </div>
                <div className="col-md-4">
                  <h5 className="section-title ff-secondary fw-normal text-start text-warning">
                    Or Call
                  </h5>
                  <p className="kkrutba-gmail-text">
                    <a
                      href="tel:+919579628028"
                      target="_blank"
                    >
                      <i className="fa fa-envelope-open text-warning me-2"></i>
                      9579628028
                    </a>
                  </p>
                </div>
                {/* <div className="col-md-4">
                  <h5 className="section-title ff-secondary fw-normal text-start text-warning">
                    Technical
                  </h5>
                  <p className="kkrutba-gmail-text">
                    <a
                      href="mailto:bhaijaan.dhaba1@gmail.com"
                      target="_blank"
                    >
                      <i className="fa fa-envelope-open text-warning me-2"></i>
                      bhaijaan.dhaba1@gmail.com
                    </a>
                  </p>
                </div> */}
              </div>
            </div>
            <div className="col-md-6 wow fadeIn" data-wow-delay="0.1s">
              {/* <iframe className="position-relative rounded w-100 h-100"
								src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3770.4010391375436!2d73.07480857423536!3d19.090054651533094!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c14c278d550d%3A0xfac80acafd450504!2sGazebo%20Dhaba%20%26%20Family%20Restaurant!5e0!3m2!1sen!2sin!4v1689254635450!5m2!1sen!2sin"
								frameBorder="0" style={{ minHeight: 350, border: 0 }} allowFullScreen="" aria-hidden="false"
								tabIndex="0"></iframe> */}

              <iframe
                className="position-relative rounded w-100 h-100"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3765.084122108016!2d73.08266577434024!3d19.322155644200453!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7bd57f1da25d9%3A0x1510316f291a7623!2sBhai%20Jaan%20Dhaba!5e0!3m2!1sen!2sin!4v1699439693903!5m2!1sen!2sin"
                frameBorder="0"
                style={{ minHeight: 350, border: 0 }}
                allowFullScreen=""
                aria-hidden="false"
                tabIndex="0"
              ></iframe>
            </div>
            <div className="col-md-6">
              <div className="wow fadeInUp" data-wow-delay="0.2s">
                {/* <form> */}
                <div className="row g-3">
                  <div className="col-md-6">
                    <div className="form-floating">
                      <input
                        className="form-control"
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Your Name"
                      />
                      <label htmlFor="name">Your Name *</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-floating">
                      <input
                        className="form-control"
                        type="email"
                        value={mail}
                        onChange={(e) => setMail(e.target.value)}
                        placeholder="Email Address*"
                      />
                      <label htmlFor="email">Your Email *</label>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-floating">
                      <input
                        className="form-control"
                        type="text"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value.replace(/[^0-9]/g, ""))}
                        placeholder="Phone Number"
                      />
                      <label htmlFor="Phone Number">Phone Number *</label>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-floating">
                      <textarea
                        className="form-control"
                        value={mess}
                        onChange={(e) => setMess(e.target.value)}
                        placeholder="Leave a message here"
                        id="message"
                        style={{ height: 150 }}
                      ></textarea>
                      <label htmlFor="message">Message *</label>
                    </div>
                  </div>
                  <div className="col-12 position-relative">
                    {isLoading ? (
                      <div className="loader"></div>
                    ) : (
                      <button
                        className="btn btn-outline-warning w-100 py-3"
                        onClick={handleSubmit}
                      >
                        Send Message
                      </button>
                    )}
                  </div>
                </div>
                {/* </form> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterComponent />
    </>
  );
};

export default ContactUsPage;
