import React from "react";


const CategoriesInServices = ({ text }) => {
  return (
    <div>
      <div>
        <div className="container-fluid pb-3">
          <div className="row mx-3 catogaries-in-service">
            <div className="col-xl-9">{text}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoriesInServices;
