import React, { useState } from "react";
import CommonBanner from "../../components/GlobalComponents/CommonBanner";
import FooterComponent from "../../components/FooterComponent";
import { Container } from "react-bootstrap";
import MenuCard from "../../components/MenuCard";
// import NoDishImage from '../../assets/images/no-dish-image.png'
// import config from "../../config";
// import axios from "axios";
// import MenuAccordion from "../../components/MenuScreen/MenuAccordion";
// import MenuCardContainer from "../../components/MenuScreen/MenuCardContainer";
// import TabMenuCardContainer from "../../components/MenuScreen/TabMenuCardContainer";

const MenuPage = () => {
  const [menu, setMenu] = useState([
    // {
    //   heading: "Menu",
    //   image: require("../../assets/images/Menu/menu-1.jpg"),
    // },
    {
      heading: "Non-Veg Starter",
      image: require("../../assets/images/Menu/non-veg-starter.jpg"),
    },
    {
      heading: "Non-Veg Gravy",
      image: require("../../assets/images/Menu/mughlahi-gravy.jpg"),
    },
    {
      heading: "Sea Food",
      image: require("../../assets/images/Menu/sea-food.jpg"),
    },
    {
      heading: "Chinese",
      image: require("../../assets/images/Menu/chinese-gravy.jpg"),
    },
    {
      heading: "Veg Starter",
      image: require("../../assets/images/Menu/veg-starter.jpg"),
    },
    {
      heading: "Rice and Bread",
      image: require("../../assets/images/Menu/Rice-and-Roti.jpg"),
    },
    {
      heading: "Deserts",
      image: require("../../assets/images/Menu/Sweets.jpg"),
    },
    {
      heading: "Catering Services",
      image: require("../../assets/images/Menu/catering-service.jpg"),
    },
    {
      heading: "Specials",
      image: require("../../assets/images/Menu/specials.jpg"),
    },
    {
      heading: "More Specials",
      image: require("../../assets/images/Menu/specials-2.jpg"),
    },
  ]);

  return (
    <>
      <CommonBanner/>
      <Container>
        {/* <div className="container">
					<div className="row ">
                  {
					menu.map((item,index)=>{
                      return(
						<MenuCard key={index} image={item.image} heading={item.heading}/>
					  )
					})
				  }
				  
				  </div>
				</div> */}
        <div className="container mt-4">
          <div className="row">
            {menu.map((item, index) => {
              return (
                <div
                  key={index}
                  className="col-12 col-xl-6 col-l-6 col-md-6 align-item-center"
                >
                  <MenuCard image={item.image} heading={item.heading} />
                </div>
              );
            })}
          </div>
        </div>
      </Container>
      <FooterComponent />
    </>
  );
};

export default MenuPage;
