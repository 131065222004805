import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUtensils } from "@fortawesome/free-solid-svg-icons";
import AboutImage1 from "../../assets/images/about/about-1.jpg";
import AboutImage2 from "../../assets/images/about/about-2.jpg";
import AboutImage3 from "../../assets/images/about/about-3.jpg";
import AboutImage4 from "../../assets/images/about/about-4.jpg";
import { Link, useLocation } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

const AboutUsComponent = () => {
  const [pathname, setPathname] = useState("");
  const location = useLocation();
  // console.log("currrent location", location.pathname);
  useEffect(() => {
    setPathname(location.pathname);
  }, []);

  return (
    <>
      <div className="container-xxl py-5 mt-4">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-6">
              <div className="row g-3">
                <div className="col-6 text-start">
                  <LazyLoadImage
                    className="img-fluid rounded w-100 wow zoomIn"
                    data-wow-delay="0.1s"
                    src={AboutImage1}
                  />
                </div>
                <div className="col-6 text-start">
                  <LazyLoadImage
                    className="img-fluid rounded w-75 wow zoomIn"
                    data-wow-delay="0.3s"
                    src={AboutImage2}
                    style={{ marginTop: "25%" }}
                  />
                </div>
                <div className="col-6 text-end">
                  <LazyLoadImage
                    className="img-fluid rounded w-75 wow zoomIn"
                    data-wow-delay="0.5s"
                    src={AboutImage3}
                  />
                </div>
                <div className="col-6 text-end">
                  <LazyLoadImage
                    className="img-fluid rounded w-100 wow zoomIn"
                    data-wow-delay="0.7s"
                    src={AboutImage4}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <h5 className="section-title ff-secondary text-start text-warning fw-normal">
                About Us
              </h5>
              <h1 className="mb-4">
                Experience the Bhaijaan Dhaba
                <span className="text-warning mx-2">
                  <FontAwesomeIcon
                    classNameName="text-warning me-3"
                    icon={faUtensils}
                  />
                </span>
                Difference
              </h1>
              <p className="mb-4">
                Hey there! Ready for a taste adventure? Bhaijaan Dhaba is like
                your foodie friend in Bhiwandi, always ready to serve up a plate
                of pure joy. Step into our world, where the smell of spices and
                the buzz of laughter blend into an unforgettable experience.
              </p>
              <p className="mb-4">
                At Bhaijaan Dhaba, we take pride in serving authentic Indian
                cuisine that captures the essence of our diverse culinary
                heritage. Established in the heart of Bhiwandi, our dhaba is a
                celebration of traditional recipes passed down through
                generations, infused with a touch of modern flair. From aromatic
                spices to hearty curries, we promise a gastronomic adventure
                that honors the spirit of India.
              </p>
              <p className="mb-4">
                Located in the heart of Bhiwandi, Bhaijaan Dhaba welcomes you to
                a dining experience that goes beyond the ordinary. For
                reservations, inquiries, or to explore our catering services,
                our dedicated and friendly staff is here to assist. Join the
                Bhaijaan family, where each visit is a celebration of India's
                culinary tapestry.
              </p>
              {pathname === "/about" ? (
                <div className="row g-4 mb-4">
                  <div className="col-sm-6">
                    <div className="d-flex align-items-center border-start border-5 border-warning px-3">
                      <h1
                        className="flex-shrink-0 display-5 text-warning mb-0"
                        data-toggle="counter-up"
                      >
                        10+
                      </h1>
                      <div className="ps-4">
                        <p className="mb-0">Years of</p>
                        <h6 className="text-uppercase mb-0">Experience</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="d-flex align-items-center border-start border-5 border-warning px-3">
                      <h1
                        className="flex-shrink-0 display-5 text-warning mb-0"
                        data-toggle="counter-up"
                      >
                        15
                      </h1>
                      <div className="ps-4">
                        <p className="mb-0">Popular</p>
                        <h6 className="text-uppercase mb-0">Master Chefs</h6>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              {pathname === "/" ? (
                <Link
                  to={"/about"}
                  className="btn btn-outline-warning py-3 px-5 mt-2 fw-bold"
                  href="#"
                >
                  Read More
                </Link>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AboutUsComponent;
