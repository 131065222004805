import React, { useState } from "react";
import CommonBanner from "../../components/GlobalComponents/CommonBanner/index.js";
import FooterComponent from "../../components/FooterComponent/index.js";
import ServicesCard from "../../components/GlobalComponents/ServicesCard.js/index.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IoFastFoodOutline } from "react-icons/io5";
import { MdBreakfastDining } from "react-icons/md";
import ServicesPageImage from "../../assets/images/servicepage/bhaijaan-dhaba-service-page-image.jpg";

import {
  faCartPlus,
  faHeadset,
  faUserTie,
  faUtensils,
} from "@fortawesome/free-solid-svg-icons";
import { Col, Row } from "react-bootstrap";
import CategoriesInServices from "../../components/CatogariesInServices/index.jsx";
import { LazyLoadImage } from "react-lazy-load-image-component";

const ServicesPage = () => {
  const [ServiceCardData, setServiceCardData] = useState([
    {
      title: "Master Chefs",
      name: "While rooted in tradition, our chefs are also innovators. They love to experiment, combining classic recipes with a dash of modern flair. The result? A menu that's as diverse as India itself, offering something for every palate. What sets our chefs apart? It's the Bhaijaan touch – a sprinkle of love, a dash of passion, and a commitment to excellence.",
      font: <FontAwesomeIcon icon={faUserTie} />,
    },
    {
      title: "Quality Food",
      name: "We believe good food starts with good ingredients. That's why, at Bhaijaan Dhaba, we pick the freshest veggies, the finest meats, and the most aromatic spices to ensure each dish bursts with flavor. From the first stir to the final garnish, we pay attention to every detail. It's not just about cooking; it's about crafting a meal that delights your taste buds and leaves you craving for more.",
      font: <FontAwesomeIcon icon={faUtensils} />,
    },
    {
      title: "Online Order",
      name: "Ordering Online Made Easy – Bhaijaan Dhaba Now Delivered to Your Doorstep! Thanks to our delivery partners: crisfood and jedlo.",
      font: <FontAwesomeIcon icon={faCartPlus} />,
    },
    {
      title: "24/7 Service",
      name: "Bhaijaan Dhaba – Where Hunger Takes No Time Off! Join us anytime, day or night, for a feast that fits your schedule. Bhaijaan Dhaba – where deliciousness never sleeps!",
      font: <FontAwesomeIcon icon={faHeadset} />,
    },
    {
      title: "Fast Food Services",
      name: "Ready, set, feast! Swing by our fast food section at Bhaijaan Dhaba and experience the joy of quick bites that never compromise on flavor. It's fast food, but with a touch of Bhaijaan magic!",
      font: <IoFastFoodOutline />,
    },
    {
      title: "Dining Services",
      name: "Your Seat Awaits – Join Us for a Dining Experience Like No Other! Whether it's a casual dinner or a special occasion, Bhaijaan Dhaba's dining services are your invitation to indulge in a memorable meal. Come, take a seat, and let the feasting begin!",
      font: <MdBreakfastDining />,
    },
  ]);

  const [categories, setCategories] = useState([
    {
      Name: "Birthday Parties With Customized Food Menu",
    },
    {
      Name: "Decor & Cake",
    },
    {
      Name: "Get Together And Kitty Parties",
    },
    { 
      Name: "Haqiqa Ceremony",
    },
    { 
      Name: "Walima Ceremony",
    },
    {
      Name: "Corporate Events And Partie",
    },
  ]);

  const [specialized, setSpecialized] = useState([
    {
      tittle: "Birthday Parties",
      image: require("../../assets/images/servicepage/btd-boy.jpg"),
      description:
        "Celebrate the joyous moments with us! Enjoy a tailor-made food menu, delightful decorations, and a cake that's as special as the birthday star. Let's make memories together!",
      approach: "Creative approach to every Menu",
      logo: require("../../assets/images/icons8-tick-96.png"),
      approachTittle1:
        "At our establishment, we understand and celebrate India is rich multicultural diversity.",
      approachTittle2:
        "At our establishment, we understand and celebrate India is rich multicultural diversity.",
      approachTittle3:
        "At our establishment, we understand and celebrate India is rich multicultural diversity.",
    },
    // {
    //   tittle: "Get Together and Kitty Parties",
    //   image: "https://kksrutbagazeebo.in/images/service/2.jpg",
    //   description:
    //     "Gather, gossip, and graze! Our place is perfect for those casual get-togethers and lively kitty parties. Delicious bites, great company, and a cozy atmosphere – the recipe for a perfect day out.Create lasting memories with friends and family in a relaxed atmosphere, perfect for casual get-togethers and lively kitty parties.",
    //   logo: require("../../assets/images/icons8-tick-96.png"),
    //   approachTittle1:
    //     "At our establishment, we understand and celebrate India is rich multicultural diversity.",
    //   approachTittle2:
    //     "At our establishment, we understand and celebrate India is rich multicultural diversity.",
    //   approachTittle3:
    //     "At our establishment, we understand and celebrate India is rich multicultural diversity.",
    // },
    {
      tittle: "Aqiqah Ceremony",
      image: require("../../assets/images/servicepage/aqiqa.jpg"),
      description:
        " Mark the joyous arrival with Bhaijaan Dhaba. Our Aqiqah services bring together delicious meals and a warm setting to make this special ceremony even more memorable. Celebrate significant life moments with our specialized services for Aqiqah and Walima ceremonies.",
      logo: require("../../assets/images/icons8-tick-96.png"),
      approachTittle1:
        "At our establishment, we understand and celebrate India is rich multicultural diversity.",
      approachTittle2:
        "At our establishment, we understand and celebrate India is rich multicultural diversity.",
      approachTittle3:
        "At our establishment, we understand and celebrate India is rich multicultural diversity.",
    },
    {
      tittle: "Walima Ceremony",
      image: require("../../assets/images/servicepage/party-time.jpg"),
      description:
        " Savor the moments of love and togetherness at Bhaijaan Dhaba. Our Walima services offer a delectable feast, a beautifully set ambiance, and the perfect space to celebrate the union of two hearts.Experience seamless catering services with a team of professionals dedicated to making your event a success.",
      logo: require("../../assets/images/icons8-tick-96.png"),
      approachTittle1:
        "At our establishment, we understand and celebrate India is rich multicultural diversity.",
      approachTittle2:
        "At our establishment, we understand and celebrate India is rich multicultural diversity.",
      approachTittle3:
        "At our establishment, we understand and celebrate India is rich multicultural diversity.",
    },
    {
      tittle: "Corporate Events and Parties",
      image: require("../../assets/images/servicepage/event.jpg"),
      description:
        "Mix business with pleasure! Elevate your corporate events with our professional touch. From impressive setups to delightful menus, we cater to your corporate needs, ensuring success with every bite.Impress clients and colleagues with our corporate event services, offering top-notch catering, decor, and professionalism.",
      logo: require("../../assets/images/icons8-tick-96.png"),
      approachTittle1:
        "At our establishment, we understand and celebrate India is rich multicultural diversity.",
      approachTittle2:
        "At our establishment, we understand and celebrate India is rich multicultural diversity.",
      approachTittle3:
        "At our establishment, we understand and celebrate India is rich multicultural diversity.",
    },
  ]);

  return (
    <>
      <CommonBanner />
      <div className="container-xxl mt-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h5 className="section-title ff-secondary text-center text-warning fw-normal">
              Our Services
            </h5>
            <h1 className="mb-5">Explore Our Services</h1>
          </div>
          <div className="row g-4">
            {ServiceCardData.map((val, index) => {
              return <ServicesCard val={val} index={index} />;
            })}
          </div>
        </div>
      </div>

      <div className="container my-5">
        <Row>
          <Col md={8}>
            <div className="servicess-rounded">
              <LazyLoadImage
                src={ServicesPageImage}
                alt=""
              />
            </div>
          </Col>
          <Col md={4}>
            <div className="bg-change-servicess mt-1">
              <h4 className="ms-4 pt-3">Categories</h4>
              <hr className="ms-3 me-3" />
              <div>
                {categories.map((item) => {
                  return (
                    <CategoriesInServices text={item.Name} number={item.id} />
                  );
                })}
              </div>
            </div>
          </Col>
        </Row>

        {specialized.map((item) => {
          return (
            <>
              <h1 className="mb-5 mt-5 services-title">{item.tittle}</h1>
              <Row>
                <Col md={6}>
                  <div className="services-description">
                    <LazyLoadImage src={item.image} alt="services" />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="desc-popular">{item.description}</div>
                </Col>
                {/* <div className="mt-3 comment-desc">{item.approach}</div>
                <div className="d-flex align-item-center  ">
                  <div className="comments-logo">
                    <LazyLoadImage src={item.logo} />
                  </div>
                  <div className="ms-3 mt-3 approach-title  ">
                    {item.approachTittle1}
                  </div>
                </div>
                <div className="d-flex align-item-center  ">
                  <div className="comments-logo">
                    <LazyLoadImage src={item.logo} />
                  </div>
                  <div className="ms-3 mt-3 approach-title">
                    {item.approachTittle2}
                  </div>
                </div>
                <div className="d-flex align-item-center ">
                  <div className="comments-logo">
                    <LazyLoadImage src={item.logo} />
                  </div>
                  <div className="ms-3 mt-3 mb-5 approach-title">
                    {item.approachTittle3}
                  </div>
                  </div> */}
              </Row>
            </>
          );
        })}
      </div>
      <FooterComponent />
    </>
  );
};

export default ServicesPage;
