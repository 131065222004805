import React from "react";

const NotFoundPage = () => {



    return (
        <>
            <div className="main-container-fof">
                <div className="fof">
                    <h1>Error 404</h1>
                </div>
            </div>
        </>
    )


}


export default NotFoundPage