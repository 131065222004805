import React from "react";
import CommonBanner from "../../components/GlobalComponents/CommonBanner";
import FooterComponent from "../../components/FooterComponent";
import BookingCardComponents from "../../components/BookingCardComponents";
import { Container } from "react-bootstrap";

const BookingPage = () => {

    return (
        <>
            <CommonBanner />
            <Container>
                <div className="menu mt-4">
                    <h5 className="section-title ff-secondary text-start text-warning fw-normal">Reserve Your Table</h5>
                    <p className="mb-4">We understand the importance of making your dining plans convenient. Use our user-friendly online reservation system to secure your table at Bhaijaan Dhaba, ensuring you have a seat waiting for you when you arrive. Join us for a dining experience that celebrates the art of cuisine, the joy of community, and the pleasure of shared moments. We can't wait to welcome you to Bhaijaan Dhaba, where every visit is a celebration of food, flavor, and friendship.  Whether you're hosting an intimate gathering or a corporate event, our private dining spaces offer a touch of exclusivity. Immerse yourself in a personalized culinary experience, tailored to your preferences and needs.</p>
                </div>
            </Container>
            <BookingCardComponents />
            <FooterComponent />
        </>
    )
}

export default BookingPage
