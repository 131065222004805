import React, { useEffect, useState } from "react";
import WhatsappLogo from "../../assets/images/svg/social/whatsapp.svg";
import InstagramLogo from "../../assets/images/svg/social/instagram.svg";
import FaceBookLogo from "../../assets/images/svg/social/facebook.svg";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faMapMarker,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import Swal from "sweetalert2";
import { LazyLoadImage } from "react-lazy-load-image-component";

const FooterComponent = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  useEffect(() => {
    scrollToTop();
  }, []);

  const [mail, setMail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = () => {
    setIsLoading(true);
    if (mail.trim() === "") {
      Swal.fire("Error", "Please fill in empty fields", "error");
      setIsLoading(false);
      return;
    }

    const emailBody = `<html>
          <body>
            <h3>Newsletter Request:</h3>
            <p><strong>Email: </strong>${mail}</p>
          </body>
        </html>`;

    const sentFrom = mail; // Replace with your email address
    const subject =
      "Newsletter request information from Bhaijaan Dhaba website";
    const dataString =
      "emailBody=" +
      encodeURIComponent(emailBody) +
      "&sentFrom=" +
      encodeURIComponent(sentFrom) +
      "&sentName=" +
      encodeURIComponent(mail) +
      "&subject=" +
      encodeURIComponent(subject) +
      "&sendmail=";

    axios
      .post("https://bhaijaandhaba.in/php_mailer/phpmailsend.php", dataString, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((response) => {
        console.log("email response", response.data);
        setIsLoading(false);
        if (response.data === "success") {
          Swal.fire("Success", "Email sent", "success");
          setMail("");
        } else {
          Swal.fire("Error", "Invalid Email Format", "error");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setIsLoading(false);
        Swal.fire(
          "Error",
          "An error occurred while sending the email",
          "error"
        );
      });

    console.log("correct");
    // return true;
  };

  return (
    <>
      <div className="whatsapp-image">
        <a
          href="https://wa.me/+919579628028"
          target="_blank"
          title="whatsapp open to chat"
        >
          <LazyLoadImage src={WhatsappLogo} />
        </a>
      </div>

      <div className="container-fluid bg-dark text-light footer pt-5 mt-5 main-footer">
        <div className="container py-5">
          <div className="row g-5">
            <div className="col-lg-3 col-md-6">
              <h4 className="section-title ff-secondary text-start text-warning fw-normal mb-4">
                Company
              </h4>
              <Link className="btn btn-link" to={"/about"}>
                About Us
              </Link>
              <Link className="btn btn-link" to={"/contact"}>
                Contact Us
              </Link>
              <Link className="btn btn-link" to={"/table-booking"}>
                Reservation
              </Link>
              <Link className="btn btn-link" to={'/services'} >
                Our Services
              </Link>
              {/* <a className="btn btn-link" href="#">
                Terms & Condition
              </a> */}
            </div>
            <div className="col-lg-3 col-md-6">
              <h4 className="section-title ff-secondary text-start text-warning fw-normal mb-4">
                Contact
              </h4>
              <p className="mb-2">
                <FontAwesomeIcon icon={faMapMarker} className="me-3" />
                Nashik Road, Sawandhe, Bhiwandi, Maharashtra 421302
              </p>
              <p className="mb-2 kkrutba-gmail-footer-text">
                <a href="tel:+919579628028" target="_blank">
                  <FontAwesomeIcon icon={faPhone} className="me-3" />
                  9579628028
                </a>
              </p>
              <p className="kkrutba-gmail-footer-text mb-2">
                <a href="mailto:bhaijaan.dhaba1@gmail.com" target="_blank">
                  <FontAwesomeIcon icon={faEnvelope} className="me-3" />
                  bhaijaan.dhaba1@gmail.com
                </a>
              </p>
              <div className="d-flex pt-2">
                <a
                  className="btn btn-outline-light btn-social"
                  target="_blank"
                  href="https://wa.me/+919579628028"
                >
                  <LazyLoadImage
                    src={WhatsappLogo}
                    alt="error"
                    className="social-svg-log"
                  />
                </a>
                <a
                  className="btn btn-outline-light btn-social"
                  href="https://www.instagram.com/bhaijaandhaba7/"
                  target="_blank"
                >
                  <LazyLoadImage
                    src={InstagramLogo}
                    alt="error"
                    className="social-svg-log"
                  />
                </a>
                <a
                  className="btn btn-outline-light btn-social"
                  href="https://www.facebook.com/pages/Bhaijaan%20Dhaba%20Bhiwandi/2301059683366268"
                  target="_blank"
                >
                  <LazyLoadImage
                    src={FaceBookLogo}
                    alt="error"
                    className="social-svg-log"
                  />
                </a>
                {/* <a className="btn btn-outline-light btn-social" href="">
								<LazyLoadImage src={LinkDinLogo} alt="" className="social-svg-log" />
							</a> */}
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <h4 className="section-title ff-secondary text-start text-warning fw-normal mb-4">
                Opening
              </h4>
              <h5 className="text-light fw-normal">Monday - Saturday</h5>
              <p>06:00 PM - 04:00 AM</p>
              <h5 className="text-light fw-normal">Sunday</h5>
              <p>06:00 PM - 04:00AM</p>
            </div>
            <div className="col-lg-3 col-md-6">
              <h4 className="section-title ff-secondary text-start text-warning fw-normal mb-4">
                Newsletter
              </h4>
              {/* <p>Dolor amet sit justo amet elitr clita ipsum elitr est.</p> */}
              <div
                className="position-relative mx-auto"
                style={{ maxWidth : 400 }}
              >
                <input
                  className="form-control border-warning w-100 py-3 ps-1 news-letter-input"
                  type="email"
                  value={mail}
                  onChange={(e) => setMail(e.target.value)}
                  placeholder="Your email*"
                />
                <div
                className="position-relative mx-auto"
                >
                  {isLoading ? (
                    <div className="position-absolute top-0 loader newsletter-loader"></div>
                  ) : (
                 
                  <span
                    className="newsletter-btn mt-3 py-2"
                    onClick={handleSubmit}
                  >
                    Send
                  </span> )}
                
              </div>
                
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="copyright">
            <div className="row">
              <div className="col-md-6 text-center text-md-start mb-3 mb-md-0 designed-by">
                &copy;{" "}
                <a className="border-bottom" href="#">
                  Bhaijaan Dhaba <span>©2023</span>{" "}
                </a>
                , All Right Reserved. Designed By{" "}
                <a
                  className="border-bottom"
                  href="http://www.crisfooddigiverse.com/"
                  target="_blank"
                >
                  Crisfood Digiverse
                </a>
              </div>
              {/* <div className="col-md-6 text-center text-md-end">
							<div className="footer-menu">
								<Link to={'/'}>Home</Link>
								<Link to={'/'}>Cookies</Link>
								<Link to={'/'}>Help</Link>
								<Link to={'/'}>FQAs</Link>
							</div>
						</div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FooterComponent;
