import React, { useEffect, useState } from "react";
import HomeScreenBanner from "../../components/HomeScreen/HomeScreenBanner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCartPlus,
  faHeadset,
  faUserTie,
  faUtensils,
} from "@fortawesome/free-solid-svg-icons";
import { IoFastFoodOutline } from "react-icons/io5";
import { MdBreakfastDining } from "react-icons/md";
import ServicesCard from "../../components/GlobalComponents/ServicesCard.js";
import AboutUsComponent from "../../components/AboutUsComponent";
import { Container, Row } from "react-bootstrap";
// import FoodMenuCard from "../../components/GlobalComponents/FoodMenuCard";
import FooterComponent from "../../components/FooterComponent";
// import NoDishImage from '../../assets/images/no-dish-image.png'
import config from "../../config";
import axios from "axios";
// import OffersCard from "../../components/OffersCard";
import NewDishes from "../../components/NewDishes/index.jsx";
import OffersCard from "../../components/OffersCard";
import CounterUpPage from "../../components/CounterComponent/index.js";
import Gallery from "../GalleryPage/index.js";
import { LazyLoadImage } from "react-lazy-load-image-component";

const HomePage = ({ scrollDemo }) => {
  const [ServiceCardData, setServiceCardData] = useState([
    {
      title: "Master Chefs",
      name: "While rooted in tradition, our chefs are also innovators. They love to experiment, combining classic recipes with a dash of modern flair. The result? A menu that's as diverse as India itself, offering something for every palate. What sets our chefs apart? It's the Bhaijaan touch – a sprinkle of love, a dash of passion, and a commitment to excellence.",
      font: <FontAwesomeIcon icon={faUserTie} />,
    },
    {
      title: "Quality Food",
      name: "We believe good food starts with good ingredients. That's why, at Bhaijaan Dhaba, we pick the freshest veggies, the finest meats, and the most aromatic spices to ensure each dish bursts with flavor. From the first stir to the final garnish, we pay attention to every detail. It's not just about cooking; it's about crafting a meal that delights your taste buds and leaves you craving for more.",
      font: <FontAwesomeIcon icon={faUtensils} />,
    },
    {
      title: "Online Order",
      name: "Ordering Online Made Easy – Bhaijaan Dhaba Now Delivered to Your Doorstep! Thanks to our delivery partners: crisfood and jedlo.",
      font: <FontAwesomeIcon icon={faCartPlus} />,
    },
    {
      title: "24/7 Service",
      name: "Bhaijaan Dhaba – Where Hunger Takes No Time Off! Join us anytime, day or night, for a feast that fits your schedule. Bhaijaan Dhaba – where deliciousness never sleeps!",
      font: <FontAwesomeIcon icon={faHeadset} />,
    },
    {
      title: "Fast Food Services",
      name: "Ready, set, feast! Swing by our fast food section at Bhaijaan Dhaba and experience the joy of quick bites that never compromise on flavor. It's fast food, but with a touch of Bhaijaan magic!",
      font:<IoFastFoodOutline />,
    },
    {
      title: "Dining Services",
      name: "Your Seat Awaits – Join Us for a Dining Experience Like No Other! Whether it's a casual dinner or a special occasion, Bhaijaan Dhaba's dining services are your invitation to indulge in a memorable meal. Come, take a seat, and let the feasting begin!",
      font: <MdBreakfastDining />,
    },
  ]);
  const [newDish, setNewDish] = useState([
    {
      image: require('../../assets/images/famous-dishes/pahadi-tikka.jpg'),
      name: "Pahadi Tikka",
      description:
        "Juicy chunks of chicken or paneer, marinated in mountain-fresh herbs, grilled to perfection for a taste of the Himalayas in every bite.",
    },
    {
      image: require('../../assets/images/famous-dishes/CHICKEN-KASTOORI-DRY.jpg'),
      name: "Chicken Kasturi Dry",
      description:
        "Succulent pieces of chicken marinated in aromatic Kasturi spices, grilled to perfection for a flavorful and dry dish that's a feast for the senses.",
    },
    {
      image: require('../../assets/images/famous-dishes/PRAWNS-TANDOORI.jpg'),
      name: "Prawns Tandoori",
      description:
        "Plump prawns marinated in Tandoori spices, charred to smoky perfection in a clay oven, offering a delightful blend of spices with a hint of smokiness.",
    },
    {
      image: require('../../assets/images/famous-dishes/tadka-fry.jpg'),
      name: "Tadka Fry",
      description:
        "Tadka Fry features a medley of vegetables or meats, pan-fried with a tempering of aromatic spices, creating a dish that's simple, yet bursting with bold flavors.",
    },
  ]);

  // const getFoodCategroy = async () => {
  // 	try {
  // 		const response = await axios.get(`${config.serverUrl}/products`);
  // 		console.log("FoodCategroy=====>>>", response.data.data);
  // 		setFoodMenuCardData(response.data.data);
  // 	} catch (error) {
  // 		// console.log("e=>", error?.message);
  // 	}
  // }

  // useEffect

  // useEffect(() => {
  // 	getFoodCategroy();
  // }, [])

  // const handleViewMore = () => {
  // 	setShowAll(true);
  // };
  // const handleViewLess = () => {
  // 	setShowAll(false);
  // };

  // const renderedData = showAll ? FoodMenuCardData : FoodMenuCardData.slice(0, 6);

  // const [activeIndex, setActiveIndex] = useState(0);
  const [offer, setOffer] = useState([]);

  // const getFoodOffers = async () => {
  // 	try {
  // 		const response = await axios.get(`${config.serverUrl}/offers`);
  // 		console.log("FoodCategroy=====>>>", response.data.data);
  // 		setOffer(response.data.data);
  // 	} catch (error) {
  // 		// console.log("e=>", error?.message);
  // 	}
  // }

  // useEffect

  // useEffect(() => {
  // 	getFoodOffers();
  // }, [])

  return (
    <>
      {/* <div ref={scrollDemo}></div> */}
      <HomeScreenBanner />
      <Container>
        {/* <h3 className="text-center mb-4 text-warning">Today Offfffers</h3>
				{offer.length === 0 ?
					<div className="container no-dish-image">
						<LazyLoadImage src={NoDishImage} alt="Error" />
						<div className="no-food-items">No Food Items Available</div>
					</div> : */}
        {/* <Row className="mt-4">
						{
							offer.map((value, index) => {
								return (
									<OffersCard value={value} index={index} />
								)
							})
						}
					</Row> */}
      </Container>
      <div className="container-xxl">
        <h3 className="text-center mt-5 mb-5 text-warning">Our Services</h3>
        <div className="container">
          <div className="row g-4">
            {ServiceCardData.map((val, index) => {
              return <ServicesCard val={val} index={index} />;
            })}
          </div>
        </div>
      </div>
      <AboutUsComponent />
      <CounterUpPage />

      <h2 align="center" className="famousDishes mb-5">
        Our Most Famous Dishes
      </h2>

      <Gallery />

      <Container>
        <h3 className="text-center mt-5 mb-5 text-warning">
          Our Latest Dishes Blogs
        </h3>
        <Row className="container">
          {newDish.map((val) => {
            return <NewDishes val={val}></NewDishes>;
          })}
        </Row>
      </Container>

      {/* {renderedData.length === 0 ?
					<div className="container no-dish-image">
						<LazyLoadImage src={NoDishImage} alt="Error" />
						<div className="no-food-items">No Food Items Available</div>
					</div>
					:
					<div className="row">
						{
							renderedData.map((value, index) => {
								return (
									<FoodMenuCard
										index={index}
										dishName={value.name}
										dishDescription={value.description}
										basePrice={value.base_price}
										discountPrice={value.discount_price}
										dishImage={value.image}
										isVeg={value.is_veg}
									/>
								)
							})

						}
					</div>}  */}
      {/* <div className="text-center ">
					</div>} */}
      {/* <div className="text-center ">
					{FoodMenuCardData.length > 6 && !showAll && (
						<button className="btn btn-outline-warning py-sm-3 px-sm-5 me-3 animated slideInLeft" onClick={handleViewMore}>View More</button>
					)}
					{showAll && (
						<button onClick={handleViewLess} className="btn btn-outline-warning py-sm-3 px-sm-5 me-3 animated slideInLeft">
							View Less
						</button>
					)}
				</div> */}
      {/* </div> */}
      <FooterComponent />
    </>
  );
};

export default HomePage;
