import React, { useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Gallery = () => {
  const [gallery, setGelley] = useState([
    {
      image: require("../../assets/images/famous-dishes/ANDA-BIRYANI.jpg"),
    },
    {
      image: require("../../assets/images/famous-dishes/CHCIKEN-TIKKA-MASALA.jpg"),
    },
    {
      image: require("../../assets/images/famous-dishes/CHICKEN-BANJARA.jpg"),
    },
    {
      image: require("../../assets/images/famous-dishes/CHICKEN-FRIED-RICE.jpg"),
    },
    {
      image: require("../../assets/images/famous-dishes/CHICKEN-KASTOORI-DRY.jpg"),
    },
    {
      image: require("../../assets/images/famous-dishes//CHICKEN-MAHARAJA-DRY.jpg"),
    },
    {
      image: require("../../assets/images/famous-dishes/CHICKEN-MASTANI-DRY.jpg"),
    },
    {
      image: require("../../assets/images/famous-dishes/CHICKEN-TANDOORI-KABAB.jpg"),
    },
    {
      image: require("../../assets/images/famous-dishes/PANEER-MASALA.jpg"),
    },
    {
      image: require("../../assets/images/famous-dishes/PRAWNS-TANDOORI.jpg"),
    },
    {
      image: require("../../assets/images/famous-dishes/SCHEZWAN-RICE.jpg"),
    },
  ]);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <>
      <Carousel
        className="container-fluid mb-4 mt-5"
        responsive={responsive}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={1000}
        containerClass="carousel-container"
      >
        {gallery.map((item) => {
          return (
            <div className="carousel-itemss gallery-ki-images">
              <LazyLoadImage
                style={{ height: "100%", width: "100%", objectFit:'cover' }}
                src={item.image}
              />
            </div>
          );
        })}
      </Carousel>
    </>
  );
};

export default Gallery;
