import React, { useEffect } from "react";
import AboutUsComponent from "../../components/AboutUsComponent";
import FooterComponent from "../../components/FooterComponent";
import CommonBanner from "../../components/GlobalComponents/CommonBanner";

const AboutUsPage = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <>
      <CommonBanner />
      <AboutUsComponent />
      <FooterComponent />
    </>
  );
};

export default AboutUsPage;
