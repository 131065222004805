import React from "react";
import { Col } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";

const NewDishes = ({ val }) => {
  return (
    <Col md={6} lg={6} sm={12} className="newDishCard">
      <div className="newDishCardBody">
        <div className="text-area">
          <div className="dishesh-blog-title">{val.name}</div>
          <div className="dishesh-blog-description">{val.description}</div>
        </div>
        <div className="image-area">
          <LazyLoadImage
            className="img-fluid wow dishCardImg"
            data-wow-delay="0.1s"
            src={val.image}
          />
        </div>
      </div>
    </Col>
  );
};

export default NewDishes;
