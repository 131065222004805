import React, { useEffect, useRef, useState } from "react";
import "./assets/styles/App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import MenuPage from "./pages/MenuPage";
import ServicesPage from "./pages/ServicesPage";
import AboutUsPage from "./pages/AboutUsPage";
import ContactUsPage from "./pages/ContactUsPage";
import CounterUpPage from "./components/CounterComponent";
import HeaderComponent from "./components/HeaderComponent";
import Gallery from "./pages/GalleryPage";
import NotFoundPage from "./pages/NotFoundPage";
import BookingPage from "./pages/BookingPage";

function App() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };


  const scrollDemo = useRef();
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    scrollToTop();
    const onScroll = () => setOffset(window.pageYOffset);
    // clean up code
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <>
      {/*----------------------------- Browser Router Section ----------------------*/}
      <BrowserRouter>
        <div className="App">
          {/*----------------------------- Header Section ----------------------*/}
          <div className="header">
            <HeaderComponent offset={offset} />
            {/*----------------------------- Toaster Section ----------------------*/}
            {/* <Toaster position="top-right1"></Toaster> */}
          </div>
          {/*----------------------------- Main Contant ----------------------*/}
          <div className="main-content">
            <Routes>
              <Route path="/" element={<HomePage scrollDemo={scrollDemo}/>} />
              <Route path="/menu" element={<MenuPage />} />
              <Route path="/services" element={<ServicesPage />} />
              <Route path="/about" element={<AboutUsPage />} />
              <Route path='/table-booking' element={<BookingPage />} />
              <Route path="/contact" element={<ContactUsPage />} />
              <Route path="/couterup" element={<CounterUpPage />} />
              <Route path="/gallery" element={<Gallery />} />
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </div>
        </div>
      </BrowserRouter>
    </>
  );
}

export default App;
