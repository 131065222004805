import { faAward, faEarth, faEarthAsia, faHotel, faPodcast, faSmile, faStar, faStarHalfAlt, faUser, faUserGraduate, faUserLargeSlash, faUserXmark, faUsers, faUsersGear } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import CountUp from 'react-countup';
import ScrollTrigger from 'react-scroll-trigger';

const CounterUpPage = () => {

  const [counterOn, setCounteron] = useState(false);

  return (
    <>
      {/* <div className="sectiontitle">
        <h2>Projects statistics</h2>
        <span className="headerLine"></span>
      </div> */}
      <ScrollTrigger onEnter={() => setCounteron(true)} onExit={() => setCounteron(false)}>
        <div id="projectFacts" className="sectionClass">
          <div className="fullWidth eight columns">
            <div className="projectFactsWrap ">
              <div className="item wow fadeInUpBig animated animated" data-number="12">
                <FontAwesomeIcon className='fontawesome-counter-icon' icon={faUser} />
                <p id="number1" className="number"> {counterOn && <CountUp start={10} end={100} delay={0} duration={2} />}k+</p>
                <span></span>
                <p className='counter-text'>Customer Serve</p>
              </div>
              <div className="item wow fadeInUpBig animated animated" data-number="55">
                <FontAwesomeIcon className='fontawesome-counter-icon' icon={faUsers} />
                <p id="number2" className="number"> {counterOn && <CountUp start={15} end={85} delay={0} duration={2} />}%</p>
                <span></span>
                <p className='counter-text'>Customers Recommend Us</p>
              </div>
              <div className="item wow fadeInUpBig animated animated" data-number="359">
                <FontAwesomeIcon className='fontawesome-counter-icon' icon={faEarthAsia} />
                <p id="number3" className="number"> {counterOn && <CountUp start={10} end={92} delay={0} duration={2} />}%</p>
                <span></span>
                <p className='counter-text'>Websites Support</p>
              </div>
              <div className="item wow fadeInUpBig animated animated" data-number="246">
                <FontAwesomeIcon className='fontawesome-counter-icon' icon={faStarHalfAlt} />
                <p id="number4" className="number"> {counterOn && <CountUp start={1200} end={2518} delay={0} duration={2} />}</p>
                <span></span>
                <p className='counter-text'>Our Company's Rating</p>
              </div>
            </div>
          </div>
        </div>
      </ScrollTrigger>
    </>
  )
}

export default CounterUpPage;
