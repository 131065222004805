import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

const MenuCard = ({ image, heading }) => {
  return (
    <div>
      <div className="menu-head my-3">
        {heading}
      </div>
      <div className="image-container my-3">
        <LazyLoadImage style={{ height: "100%", width: "100%" }} src={image} />
      </div>
    </div>
  );
};

export default MenuCard;
