import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const CommonBanner = () => {
  {
    /*------------------------------- useLocation Get Current Location --------------------------*/
  }
  const location = useLocation();
  const [pathname, setPathname] = useState("");
  // console.log("currrent location", location.pathname);

  {
    /*------------------------------- useEffect Component --------------------------*/
  }
  useEffect(() => {
    setPathname(location.pathname);
  }, []);

  return (
    <div className="py-5 bg-dark hero-header">
      <div className="container text-center my-5 pt-5 pb-4">
        {pathname === "/services" && (
          <h1 className="display-3 text-white mb-3 animated slideInDown">
            Our Services
          </h1>
        )}
        {pathname === "/menu" && (
          <h1 className="display-3 text-white mb-3 animated slideInDown">
            Menu
          </h1>
        )}
        {pathname === "/about" && (
          <h1 className="display-3 text-white mb-3 animated slideInDown">
            About Us
          </h1>
        )}
        {pathname === "/contact" && (
          <h1 className="display-3 text-white mb-3 animated slideInDown">
            Contact Us
          </h1>
        )}
        {pathname === "/table-booking" && (
          <h1 className="display-3 text-white mb-3 animated slideInDown">
            Table Booking
          </h1>
        )}
      </div>
    </div>
  );
};
export default CommonBanner;
