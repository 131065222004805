import React from "react";
import HeroImage from "../../../assets/images/png/hero.png";
import { LazyLoadImage } from "react-lazy-load-image-component";

const HomeScreenBanner = () => {
  return (
    <>
      <div className="py-5 hero-header">
        <div className="container my-5 py-5">
          <div className="row align-items-center">
            <div className="col-lg-6 text-center text-lg-start text-card">
              <h1 className="display-5 text-white animated slideInLeft">
                Welcome to Bhaijaan Dhaba
              </h1>
              <p className="text-white animated slideInLeft mb-4 pb-2">
                Where Authentic Indian Flavors Meet Heartwarming Hospitality in
                the Heart of Bhiwandi!
              </p>
              <a
                className="btn btn-outline-warning py-sm-3 px-sm-5 me-3 animated slideInLeft mb-4"
                href="https://drive.google.com/file/d/10l5PnwW2ZshWn5FvuBYQzGZo5s1BqPjj/view?usp=sharing "
                target="_blank"
              >
                Download Menu Now
              </a>
            </div>
            <div className="col-lg-6 text-center text-lg-end overflow-hidden">
              <LazyLoadImage className="img-fluid" src={HeroImage} alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default HomeScreenBanner;
