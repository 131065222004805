import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import { NavLink, useLocation } from "react-router-dom";
import { Container } from "react-bootstrap";
import logo from "../../assets/images/bhaijaan-dhaba-logo.png";
import Namelogo from "../../assets/images/name-logo.png";
import { LazyLoadImage } from "react-lazy-load-image-component";

const HeaderComponent = ({ offset }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [pathname, setPathname] = useState("");
  const location = useLocation();
  // console.log("currrent location", location.pathname);
  useEffect(() => {
    setPathname(location.pathname);
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <>
      <header
        className={
          pathname == "/"
            ? offset >= 350
              ? "header-with-scroll"
              : "header-without-scroll"
            : offset >= 250
              ? "header-with-scroll"
              : "header-without-scroll"
        }
      >
        <Container>
          <nav>
            <NavLink to={"/"} className="logo">
              <LazyLoadImage src={logo} alt="Restaurant Logo" className="logo-image" />
              {/* <span className="restaurant-logo-text">Bhaijaan Dhaba</span> */}
              <LazyLoadImage src={Namelogo} className="namelogo-image" alt="Restaurant Logo" />
            </NavLink>
            <div className={`navigation-text ${menuOpen ? "open" : ""}`}>
              <NavLink to={"/"} onClick={toggleMenu}>
                Home
              </NavLink>
              <NavLink to={"/menu"} onClick={toggleMenu}>
                Menu
              </NavLink>
              <NavLink to={"/table-booking"} onClick={toggleMenu}>
                Booking
              </NavLink>
              <NavLink to={"/about"} onClick={toggleMenu}>
                About
              </NavLink>
              <NavLink to={"/contact"} onClick={toggleMenu}>
                Contact
              </NavLink>
              <NavLink to={"/services"} onClick={toggleMenu}>
                Our Services
              </NavLink>
            </div>
            <div className="show-close-button">
              {/* {menuOpen && (
								<div className="close-button" onClick={toggleMenu}>
									<FontAwesomeIcon icon={faTimes} />
								</div>
							)} */}
              {menuOpen ? (
                <div className="close-button" onClick={toggleMenu}>
                  <FontAwesomeIcon icon={faTimes} />
                </div>
              ) : (
                <div className="hamburger" onClick={toggleMenu}>
                  <FontAwesomeIcon icon={faBars} />
                </div>
              )}
            </div>
          </nav>
        </Container>
      </header>
    </>
  );
};

export default HeaderComponent;
