import axios from "axios";
import React, { useState } from "react";
import Swal from "sweetalert2";
import config from "../../config";
import DatePicker from "react-date-picker";
import moment from "moment";

const BookingCardComponents = () => {
  const [mail, setMail] = useState("");
  const [totalPerson, setTotalPerson] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [mobileNaumber, setMobileNaumber] = useState("");
  const [bookingTime, setBookingTime] = useState("10:00");
  const [bookingDate, setBookingDate] = useState(new Date());

  // ------------------------- Time convert ----------------

  const convertTo12HourFormat = (time24) => {
    const [hours, minutes] = time24.split(":").map(Number);

    if (hours < 0 || hours > 23 || minutes < 0 || minutes > 59) {
      setBookingTime(
        "Invalid time format. Please enter a valid 24-hour time (e.g., 10:00)"
      );
      return;
    }

    let period = "AM";
    let hours12 = hours;
    if (hours >= 12) {
      period = "PM";
      if (hours > 12) {
        hours12 = hours - 12;
      }
    }
    if (hours === 0) {
      hours12 = 12;
    }

    const time12 = `${hours12.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")} ${period}`;

    return time12;
  };

  /*-------------------------------------------------------------
																			 Add Food Booking
			---------------------------------------------------------------*/
  const [isLoading, setIsLoading] = useState(false);

  const addFoodBooking = () => {
    setIsLoading(true);
    if (
      !customerName.trim() ||
      !totalPerson.trim() ||
      !mail.trim() ||
      mobileNaumber.length < 10 ||
      bookingDate === null ||
      !bookingTime.trim()
    ) {
      Swal.fire(
        "Error",
        `${
          mobileNaumber && mobileNaumber.length < 10
            ? "Mobile number must be at least 10 characters long"
            : "Please fill in all fields"
        }`,
        "error"
      );
      setIsLoading(false);
      return;
    }

    const emailBody = `<html>
                <body>
                  <h3>Table Booking Request Details:</h3>
                  <p><strong>Customer Name: </strong>${customerName}</p>
                  <p><strong>Email: </strong>${mail}</p>
                  <p><strong>Contact Number: </strong>${mobileNaumber}</p>
                  <p><strong>Total Person: </strong>${totalPerson}</p>
                  <p><strong>Booking Date: </strong>${moment(
                    bookingDate
                  ).format("LL")}</p>
                  <p><strong>Booking Time: </strong>${convertTo12HourFormat(
                    bookingTime
                  )}</p>
                </body>
              </html>`;

    const sentFrom = mail; // Replace with your email address
    const sentName = customerName;
    const subject =
      "Table booking request information from Bhaijaan Dhaba website";
    const dataString =
      "emailBody=" +
      encodeURIComponent(emailBody) +
      "&sentFrom=" +
      encodeURIComponent(sentFrom) +
      "&sentName=" +
      encodeURIComponent(sentName) +
      "&subject=" +
      encodeURIComponent(subject) +
      "&sendmail=";

    axios
      .post("https://bhaijaandhaba.in/php_mailer/phpmailsend.php", dataString, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((response) => {
        console.log("email response", response.data);
        setIsLoading(false);
        if (response.data === "success") {
          Swal.fire("Success", "Email sent", "success");
          setCustomerName("");
          setMobileNaumber("");
          setTotalPerson("");
          setMail("");
          setBookingTime("10:00");
          setBookingDate(new Date());
        } else {
          Swal.fire("Error", "Failed to send email", "error");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setIsLoading(false);
        Swal.fire(
          "Error",
          "An error occurred while sending the email",
          "error"
        );
      });

    console.log("correct");
    // return true;
  };

  const handleBookingDate = (val) => {
    setBookingDate(val);
  };

  return (
    <>
      <div
        className="container-xxl py-5 px-0 wow fadeInUp"
        data-wow-delay="0.1s"
      >
        <div className="row g-0">
          <div className="col-md-6">
            <div className="video">
              <a
                className="btn-play"
                data-bs-toggle="modal"
                href="#"
                data-bs-target="#videoModal"
              >
                <span></span>
              </a>
            </div>
          </div>
          <div className="col-md-6 bg-dark d-flex align-items-center">
            <div className="p-5 wow fadeInUp" data-wow-delay="0.2s">
              <h5 className="section-title ff-secondary text-start text-warning fw-normal">
                Reservation
              </h5>
              <h1 className="text-white mb-4">Book A Table Online</h1>
              <div className="row g-3">
                <div className="col-md-6">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      placeholder="Your Name"
                      value={customerName}
                      onChange={(e) => setCustomerName(e.target.value)}
                    />
                    <label htmlFor="name">Your Name *</label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      id="phone_number"
                      placeholder="Phone Number"
                      value={mobileNaumber}
                      onChange={(e) =>
                        setMobileNaumber(e.target.value.replace(/[^0-9]/g, ""))
                      }
                    />
                    <label htmlFor="PhoneNumber">Phone Number *</label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-floating">
                    <input
                      className="form-control"
                      type="email"
                      value={mail}
                      onChange={(e) => setMail(e.target.value)}
                      placeholder="Email Address*"
                    />
                    <label htmlFor="email">Your Email *</label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      id="total_person"
                      placeholder="Total Person"
                      value={totalPerson}
                      onChange={(e) =>
                        setTotalPerson(e.target.value.replace(/[^0-9]/g, ""))
                      }
                    />
                    <label htmlFor="TotalPerson">Total Person *</label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-floating">
                    <input
                      type="time"
                      className="form-control fs-5"
                      id="Booking_Time"
                      placeholder="Booking Time"
                      value={bookingTime}
                      onChange={(e) => setBookingTime(e.target.value)}
                    />
                    <label htmlFor="BookingTime">Booking Time *~</label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-floating">
                    {/* <input type="date" className="form-control" id="Booking_Date" placeholder="Booking Date"
											value={bookingDate} onChange={e => setBookingDate(e.target.value)}
										/> */}
                    <DatePicker
                      onChange={(value) => handleBookingDate(value)}
                      value={bookingDate}
                      format={"dd:MM:y"}
                      minDate={new Date()}
                    />
                    {/* <label htmlFor="BookingTime">Booking Date</label> */}
                  </div>
                </div>
                {/* <div className="col-12">
                                        <div className="form-floating">
                                            <textarea className="form-control" placeholder="Special Request" id="message" style={{ height: "100%" }}></textarea>
                                            <label htmlFor="message">Special Request</label>
                                        </div>
                                    </div> */}
                <div className="col-12 position-relative">
                  {isLoading ? (
                    <div className="loader"></div>
                  ) : (
                    <button
                      className="btn btn-outline-warning w-100 py-3"
                      onClick={addFoodBooking}
                    >
                      Book Now
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="modal fade" id="videoModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
				<div className="modal-dialog">
					<div className="modal-content rounded-0">
						<div className="modal-header">
							<h5 className="modal-title" id="exampleModalLabel">Youtube Video</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div className="modal-body">
							<div className="ratio ratio-16x9">
								<iframe className="embed-responsive-item" src="" id="video" allowFullScreen allowscriptaccess="always"
									allow="autoplay"></iframe>
							</div>
						</div>
					</div>
				</div>
			</div> */}
    </>
  );
};

export default BookingCardComponents;
